<template>
  <div class="bg-white h-100 custom-overflow rounded-lg pa-4 pt-0">
    <div
      class="h-100px bg-white position-sticky top-0 left-0 zindex-5 d-flex align-center justify-end flex-wrap px-4"
    >
      <v-text-field
        v-model="sku"
        class="max-w-250px"
        :label="
          $store.getters.getWMSRECEIVINGReceivingType === 3
            ? carton_number === null
              ? 'Scan carton'
              : 'Scan SKU'
            : 'Scan SKU'
        "
        clearable
        dense
        outlined
        hide-details
        hide-spin-buttons
        @keydown.enter="handleScan"
      />
      <div
        class="d-flex"
        v-if="!Boolean($store.getters.getWMSRECEIVINGIs_BAQ_ASN)"
      >
        <button class="btn btn--export-filter ml-3" @click="addTote">
          <span class="svg-icon">
            <v-icon size="18">mdi-plus-box-outline</v-icon>
          </span>
          Tote
        </button>
        <button class="btn btn--export-filter ml-3" @click="addLPN">
          <span class="svg-icon">
            <v-icon size="18">mdi-plus-box-outline</v-icon>
          </span>
          LPN
        </button>
      </div>
    </div>

    <template v-if="!Boolean($store.getters.getWMSRECEIVINGIs_BAQ_ASN)">
      <v-data-table
        v-model="selected"
        dense
        disable-sort
        :headers="skuHeaders"
        :items="$store.getters.getWMSRECEIVINGScannedSKUS"
        item-key="id"
        :footer-props="{ 'items-per-page-options': [50, 40, 30, 20] }"
        class="my-6 px-4"
        show-select
        checkbox-color="#7e04b7"
        mobile-breakpoint="300"
        @dblclick:row="handleDoubleClickOnRow"
      >
        <template v-slot:item.sku="{ value }">{{ value }}</template>

        <template v-slot:item.photo="{ item }">
          <ShowImage :item="item" />
        </template>

        <template v-slot:item.qty="{ value }">{{ value }}</template>

        <template v-slot:item.flags="{ item }">
          <div class="d-flex flex-wrap max-w-250px">
            <span
              v-if="item.good_or_damage === 1"
              class="text-center badge badge-light-success font-size-sm my-2 mr-2 py-2 px-4"
            >
              Good
            </span>
            <span
              v-else
              class="text-center badge badge-light-warning font-size-sm my-2 mr-2 py-2 px-4"
            >
              Damaged
            </span>
            <span
              v-if="item.label_flag"
              class="text-center badge badge-light-success font-size-sm my-2 mr-2 py-2 px-4"
            >
              Labelled
            </span>
            <span
              v-if="item.bundle_flag"
              class="text-center badge badge-light-primary font-size-sm my-2 mr-2 py-2 px-4"
            >
              Bundled
            </span>
            <span
              v-if="item.qc_flag"
              class="text-center badge badge-light-info font-size-sm my-2 mr-2 py-2 px-4"
            >
              QC
            </span>
            <span
              v-if="item.assemble_flag"
              class="text-center badge badge-light-warning font-size-sm my-2 mr-2 py-2 px-4"
            >
              Assembled
            </span>
            <span
              v-if="item.exp_date"
              class="text-center badge badge-light-danger font-size-sm my-2 mr-2 py-2 px-4"
            >
              EXP: {{ item.exp_date }}
            </span>
          </div>
        </template>

        <template v-slot:item.tote_id="{ value }"> {{ value }}</template>

        <template v-slot:item.lpn_id="{ value }"> {{ value }}</template>

        <template v-slot:item.transfer_flag="{ item, value }">
          <button
            v-if="value"
            class="btn iq-btn me-3"
            @click="() => $refs.skuDialog.editSKU(item, true)"
          >
            Split
          </button>
        </template>
      </v-data-table>

      <SKUActionsDialog ref="skuDialog" />
      <ToteLPNDialog ref="toteLPNDialog" />
    </template>

    <template v-else>
      <v-data-table
        v-model="selected"
        dense
        disable-sort
        :headers="headersForISS"
        :items="$store.getters.getWMSRECEIVINGCartons_For_ISS"
        item-key="tote_id"
        :footer-props="{ 'items-per-page-options': [50, 40, 30, 20] }"
        class="my-6 px-4"
        mobile-breakpoint="300"
      >
        <template v-slot:item.tote_id="{ value }"> {{ value }}</template>

        <template v-slot:item.actions="{ item }">
          <button class="btn iq-btn me-3" @click="() => editBAQ(item.tote_id)">
            Edit
          </button>

          <button class="btn iq-btn" @click="() => printBAQ(item.tote_id)">
            Print
          </button>
        </template>
      </v-data-table>
    </template>

    <EditCartonForISS ref="editCartonForISS" />

    <ScanCaseCodeModal
      ref="scanCaseCodeModal"
      :editBAQ="editBAQ"
      :printBAQ="printBAQ"
      :resetReceivingData="resetReceivingData"
    />
  </div>
</template>

<script>
import EditCartonForISS from "@/own/components/warehouseManagement/receiving/ISS/EditCartonForISS.vue";
import ScanCaseCodeModal from "@/own/components/warehouseManagement/receiving/ISS/ScanCaseCodeModal.vue";
import SKUActionsDialog from "@/own/components/warehouseManagement/receiving/SKUActionsDialog";
import Swal from "sweetalert2";
import ToteLPNDialog from "@/own/components/warehouseManagement/receiving/ToteLPNDialog";
import ShowImage from "@/own/components/warehouseManagement/receiving/ShowImage";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import {
  ADD_SCANNED_SKUS,
  SET_SCANNED_CARTONS_FOR_ISS,
  SET_SCANNED_SKUS,
} from "@/core/services/store/receiving.module";

export default {
  name: "ReceivingDataTable",
  props: ["asn_number", "resetReceivingData"],
  components: {
    ShowImage,
    ToteLPNDialog,
    SKUActionsDialog,
    EditCartonForISS,
    ScanCaseCodeModal,
  },
  data: () => ({
    selected: [],
    sku: "",
    carton_number: null,
  }),
  computed: {
    skuHeaders: function () {
      const headers = [
        {
          text: "SKU",
          value: "sku",
        },
        {
          text: "Photo",
          value: "photo",
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
        },
        {
          text: "Flags",
          value: "flags",
        },
        {
          text: "Tote",
          value: "tote_id",
        },
        {
          text: "LPN",
          value: "lpn_id",
        },
      ];

      if (this.$store.getters.getWMSRECEIVINGReceivingType === 3)
        headers.push({
          text: "Actions",
          value: "transfer_flag",
        });

      return headers;
    },
    headersForISS: function () {
      // const headers = [
      return [
        { text: "Case code", value: "tote_id", align: "center" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ];

      // return headers;
    },
    scanningType: function () {
      return this.$store.getters.getWMSRECEIVINGReceivingType === 3
        ? "carton"
        : "sku";
    },
  },
  methods: {
    editBAQ(value) {
      this.$store.commit(SET_PAGE_LOADING, true);
      const sendData = {
        // warehouse_id: this.$store.getters.getSelectedWarehouse,
        asn_number: this.asn_number,
        carton_number: value,
      };
      this.$refs.editCartonForISS.loadDataFromServer(sendData);
    },
    printBAQ(value) {
      this.$store.commit(SET_PAGE_LOADING, true);
      const sendData = {
        asn_number: this.asn_number,
        carton_number: value,
      };
      ApiService.post(
        "/warehouse_management/receiving/print_carton_number",
        sendData
      ).then(({ data }) => {
        const link = document.createElement("a");
        link.href = data.path;
        link.target = "_blank";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          link.remove();
        }, 2500);
      });
    },
    toggleResetData() {
      this.selected = [];
      this.sku = "";
      this.carton_number = null;
    },
    handleScan() {
      if (!this.sku.length) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      const sendData = {
        asn_number: this.asn_number,
        type: this.$store.getters.getWMSRECEIVINGBlindReceive
          ? "blind_receiving"
          : "receiving",
        sku: this.sku.trim(),
        scanning_type: this.scanningType,
      };

      // CANCELSKU1
      if (this.carton_number) {
        sendData.carton_number = this.carton_number;
        sendData.scanning_type = "carton_scan_sku";
      }
      ApiService.post("/warehouse_management/receiving/validate_sku", sendData)
        .then(({ data }) => {
          //  this flag only for ISS project
          if (data.success) {
            if (sendData.scanning_type === "carton") {
              if (!data.skus.length) {
                Swal.fire({
                  title: "Warning",
                  text: "You have already added this carton",
                  icon: "warning",
                });

                return;
              }
            }

            if (this.$store.getters.getWMSRECEIVINGIs_BAQ_ASN) {
              const foundCarton =
                this.$store.getters.getWMSRECEIVINGCartons_For_ISS.find(
                  (elem) => elem.tote_id === data.skus[0].tote_id
                );
              if (!foundCarton) {
                this.$store.commit(ADD_SCANNED_SKUS, data.skus);
                this.$store.commit(SET_SCANNED_CARTONS_FOR_ISS, {
                  tote_id: data.skus[0].tote_id,
                });
                if (data.message) {
                  if (data.message.includes("This is ready case")) {
                    this.$refs.scanCaseCodeModal.toggleModal(
                      this.asn_number,
                      data.skus[0].tote_id,
                      true,
                      data.message,
                      data.skus[0].id
                      // data.skus[0].sku  previously we were checking with sku
                    );
                  } else {
                    this.$refs.scanCaseCodeModal.toggleModal(
                      this.asn_number,
                      data.skus[0].tote_id,
                      false,
                      data.message,
                      null
                    );
                  }
                }
              } else {
                Swal.fire({
                  title: "Warning",
                  text: "You have already added this carton",
                  icon: "warning",
                });

                return;
              }
            }
            if (sendData.scanning_type === "sku") {
              if (!data.sku) {
                Swal.fire({
                  title: "Warning",
                  text: "Item not found",
                  icon: "warning",
                });

                return;
              }

              if (data.sku.is_allow_over_receiving) {
                if (!this.$store.getters.getWMSRECEIVINGSimpleReceive)
                  this.$refs.skuDialog.toggleModal(data.sku);
                else {
                  const sku =
                    this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
                      (item) => item.sku === data.sku.sku
                    );

                  if (sku) {
                    sku.qty += 1;
                  } else {
                    let sendData = {
                      id: new Date().getTime(),
                      sku: data.sku.sku,
                      qty: 1,
                      description: data.sku.description,
                      photo: data.sku.photo,
                      transfer_flag: data.sku?.transfer_flag,

                      label_flag: false,
                      bundle_flag: false,
                      qc_flag: false,
                      assemble_flag: false,
                      exp_flag: false,
                      exp_date: null,
                      good_or_damage: 1,
                      tote_id: "",
                      lpn_id: "",
                    };
                    // console.log("submitForm ssssss", sendData);
                    this.$store.commit(SET_SCANNED_SKUS, sendData);
                  }
                }
              } else {
                const skus =
                  this.$store.getters.getWMSRECEIVINGScannedSKUS.filter(
                    (item) => item.sku === data.sku.sku
                  );

                let leftQty = data.sku.qty;
                if (skus.length) {
                  skus.forEach((item) => {
                    leftQty -= item.qty;
                  });
                }

                // console.log("leftQty", leftQty);
                if (leftQty !== 0) {
                  if (!this.$store.getters.getWMSRECEIVINGSimpleReceive)
                    this.$refs.skuDialog.toggleModal(data.sku);
                  else {
                    const sku =
                      this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
                        (item) => item.sku === data.sku.sku
                      );

                    if (sku) {
                      sku.qty += 1;
                    } else {
                      let sendData = {
                        id: new Date().getTime(),
                        sku: data.sku.sku,
                        qty: 1,
                        description: data.sku.description,
                        photo: data.sku.photo,
                        transfer_flag: data.sku?.transfer_flag,

                        label_flag: false,
                        bundle_flag: false,
                        qc_flag: false,
                        assemble_flag: false,
                        exp_flag: false,
                        exp_date: null,
                        good_or_damage: 1,
                        tote_id: "",
                        lpn_id: "",
                      };
                      // console.log("submitForm ssssss", sendData);
                      this.$store.commit(SET_SCANNED_SKUS, sendData);
                    }
                  }
                } else
                  Swal.fire({
                    title: "Warning",
                    text: "You have reached the expected quantity",
                    icon: "warning",
                  });
              }
            } else if (sendData.scanning_type === "carton") {
              if (!data.skus || !data.skus?.length) {
                Swal.fire({
                  title: "Warning",
                  text: "Item not found",
                  icon: "warning",
                });

                return;
              }

              if (this.$store.getters.getWMSRECEIVINGCanDecideReceivingType) {
                Swal.fire({
                  title: "Warning",
                  text: "Please, select scanning type",
                  icon: "warning",
                  showConfirmButton: true,
                  confirmButtonText: "Carton",
                  confirmButtonColor: "#7e04b7",
                  showCancelButton: true,
                  cancelButtonText: "SKU",
                  cancelButtonColor: "#F64E60FF",
                }).then((result) => {
                  if (result.isDismissed !== "backdrop") {
                    if (result.isConfirmed) {
                      this.carton_number = null;
                      // means carton is selected
                      const foundCarton =
                        this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
                          (elem) => elem.tote_id === sendData.sku
                        );

                      if (!foundCarton) {
                        this.$store.commit(ADD_SCANNED_SKUS, data.skus);
                      } else {
                        Swal.fire({
                          title: "Warning",
                          text: "You have already added this carton items",
                          icon: "warning",
                        });
                      }
                    } else {
                      // means SKU is selected
                      this.carton_number = sendData.sku;
                    }
                  }
                });
              } else {
                if (!this.$store.getters.getWMSRECEIVINGIs_BAQ_ASN) {
                  this.carton_number = null;
                  let foundCarton =
                    this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
                      (elem) => elem.tote_id === this.sku
                    );
                  if (!foundCarton) {
                    this.$store.commit(ADD_SCANNED_SKUS, data.skus);
                  } else {
                    Swal.fire({
                      title: "Warning",
                      text: "You have already added this carton items",
                      icon: "warning",
                    });
                  }
                }
              }
            } else if (sendData.scanning_type === "carton_scan_sku") {
              if (!data.sku) {
                Swal.fire({
                  title: "Warning",
                  text: "Item not found",
                  icon: "warning",
                });

                return;
              }

              let sku = null;
              let totalQty = 0;
              // const sku = this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
              //   (item) => item.id === data.sku.id
              // );
              this.$store.getters.getWMSRECEIVINGScannedSKUS.forEach((item) => {
                if (item.id === data.sku.id) {
                  totalQty += item.qty;

                  if (!sku && item.sku === sendData.sku) sku = item;
                }
              });
              if (totalQty >= data.sku.expected_qty) {
                Swal.fire({
                  title: "Warning",
                  text: "You have reached the expected quantity",
                  icon: "warning",
                });
              } else {
                if (sku) {
                  if (sku.qty < data.sku.expected_qty) sku.qty += 1;
                  else
                    Swal.fire({
                      title: "Warning",
                      text: "You have reached the expected quantity",
                      icon: "warning",
                    });
                } else {
                  // console.log("data", data);
                  let sendDataForScanned = {
                    id: data.sku.id,
                    sku: data.sku.sku,
                    qty: 1,
                    photo: data.sku.photo,
                    description: data.sku.description,
                    transfer_flag: Boolean(data.sku.transfer_flag),

                    label_flag: false,
                    bundle_flag: false,
                    qc_flag: false,
                    assemble_flag: false,
                    exp_flag: false,
                    exp_date: null,
                    good_or_damage: 1,
                    tote_id: this.carton_number,
                    lpn_id: "",
                  };
                  this.$store.commit(SET_SCANNED_SKUS, sendDataForScanned);
                }
              }
            }
          } else
            Swal.fire({
              title: data.message,
              text: data.errors,
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonColor: "#F64E60FF",
            });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.sku = "";
        });
    },
    handleDoubleClickOnRow(_, { item }) {
      if (!this.$store.getters.getWMSRECEIVINGSimpleReceive)
        this.$refs.skuDialog.editSKU(item);
    },
    addTote() {
      if (this.selected.length === 0)
        Swal.fire({
          title: "Warning",
          text: `Please, select item!`,
          icon: "warning",
          showConfirmButton: true,
        });
      else this.$refs.toteLPNDialog.toggleModal(this.selected, "tote");
    },
    addLPN() {
      if (this.selected.length === 0)
        Swal.fire({
          title: "Warning",
          text: `Please, select item!`,
          icon: "warning",
          showConfirmButton: true,
        });
      else this.$refs.toteLPNDialog.toggleModal(this.selected, "LPN");
    },
  },
  watch: {
    asn_number(newValue) {
      if (newValue === null) {
        this.sku = "";
        this.carton_number = null;
      }
    },
  },
};
</script>

<style scoped>
.custom-overflow {
  overflow-y: scroll;
  position: relative;
}

.custom-overflow::-webkit-scrollbar {
  width: 12px !important;
}

.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
}
</style>
